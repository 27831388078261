import '../sass/pages/projecting.scoped.scss';
import React from 'react';
import Seo from '../components/Seo';
import Layout from '../components/blocks/Layout';
import { StaticImage } from 'gatsby-plugin-image';
import Search from '../icons/search.svg';
import Archive from '../icons/archive.svg';
import Talk from '../icons/talk.svg';
import Plan from '../icons/plan.svg';
import Apply from '../icons/apply.svg';
import Finger from '../icons/finger.svg';
import { ModalProvider } from '../context/ModalContext';
import { useModal } from '../context/ModalContext';

const ProjectingContainer = () => {
  return (
    <ModalProvider>
      <Projecting />
    </ModalProvider>
  );
};

const Projecting = () => {
  const { dispatch } = useModal();
  return (
    <Layout>
      <Seo title='Проектирование' />
      <h1>Проектирование систем вентиляции и кондиционирования</h1>
      <div className='content'>
        <div className='block-with-img'>
          <section className='text-section'>
            <p>
              В настоящее время предъявляются высокие технические требования к
              проектированию систем вентиляции и кондиционирования по
              энергоэффективности, пожаробезопасности и экологичности. Качество
              разработки проекта является серьезным этапом по обеспечению
              оптимальных параметров микроклимата и комфорта обслуживаемого
              здания, а также бесперебойного обогрева помещений в случае
              воздушной системы отопления в отопительный период.
            </p>
            <p>
              К основным документам, которыми руководствуются специалисты при
              разработке проекта систем вентиляции и кондиционирования,
              относятся СН РК 4.02-01-2011 «Отопление, вентиляция и
              кондиционирование», СП РК 2.04-01-2017 «Строительная
              климатология», ГОСТ 12.1.005-88 «Общие санитарно-гигиенические
              требования к воздуху рабочей зоны».
            </p>
            <div className='list'>
              <p className='list-heading'>
                ТОО «Invest Group Company» предоставляет услуги по
                проектированию систем вентиляции и кондиционирования в:
              </p>
              <ul>
                <li>Барах и ресторанах</li>
                <li>Торговых центрах и офисах</li>
                <li>Образовательных учреждениях</li>
                <li>Складских помещениях</li>
                <li>Промышленных предприятиях</li>
                <li>Аграрном секторе</li>
              </ul>
            </div>
            <button
              className='btn'
              onClick={() => {
                dispatch({ type: 'open' });
              }}>
              <Finger className='icon-finger' />
              <span>Заказать расчет проекта</span>
              <span className='decoration'>
                <StaticImage
                  className="shake"
                  src='../images/leaves/leaf-3.png'
                  alt='Лист дерева'
                  placeholder="none"
                />
              </span>
            </button>
          </section>
          <div className='project-img'>
            <StaticImage src='../images/project.jpg' alt='Проект' placeholder='none' />
          </div>
        </div>
        <section className='text-section'>
          <h2 className='section-heading'>
            Проект вентиляции и кондиционирования – этапы проектирования
          </h2>
          <p>
            Проектирование вентиляции и кондиционирования состоит из отдельных
            этапов. Первоначальным этапом проектирования любой инженерной
            системы или сети является сбор исходных данных, к которым относятся:
            информация о назначении помещения, количество одновременно
            находящихся в помещении людей, тепловыделения от технологического
            оборудования, освещения, предпочтения по виду используемого
            источника тепла, технические условия на подключение к сетям,
            требования по автоматизации и диспетчеризации и т.д. Наилучшим
            образом эта информация отображается в тщательно заполненном опросном
            листе.
          </p>
          <p>
            Далее на основании данных полученных от клиента специалист
            производит предварительные расчеты, выбор основного оборудования,
            разработку принципиальной схемы вентиляции и кондиционирования и
            направляет клиенту на принципиальное согласование принятые решения.
            На данной стадии также производят технико-экономическое сравнение
            вентиляционных систем в зависимости от применяемой схемы, виду
            используемого источника тепла и др.
          </p>
          <p>
            Особое внимание специалисты ТОО «Invest Group Company» уделяют
            компоновке и размещению оборудования, а также разводке воздуховодов
            и трубопроводов. Необходимо помнить, что оборудование требует
            периодического обслуживания и для этого требуется учитывать
            расстояние проходов между оборудованием, стеной и оборудованием, а
            также расстояние свободных проходов под трубопроводами и др.
          </p>
          <p>
            После окончательного и утверждённого размещения основного и
            вспомогательного оборудования, разводки всех воздуховодов и
            трубопроводов разрабатываются раздел автоматизации, раздел охрана
            окружающей среды и составляются сметные расчеты.
          </p>
        </section>
        <section className='text-section'>
          <h2 className='section-heading'>Как мы работаем</h2>
          <p>
            Проектирование вентиляции и кондиционирования состоит из отдельных
            этапов. Первоначальным этапом проектирования любой инженерной
            системы или сети является сбор исходных данных, к которым относятся:
            информация о назначении помещения, количество одновременно
            находящихся в помещении людей, тепловыделения от технологического
            оборудования, освещения, предпочтения по виду используемого
            источника тепла, технические условия на подключение к сетям,
            требования по автоматизации и диспетчеризации и т.д. Наилучшим
            образом эта информация отображается в тщательно заполненном опросном
            листе.
          </p>
          <div className='steps'>
            <div className='step'>
              <div className='step-icon'>
                <Search />
              </div>
              <span className='step-text'>Сбор исходных данных</span>
            </div>
            <div className='step'>
              <div className='step-icon'>
                <Archive />
              </div>
              <span className='step-text'>Выбор принципиального решения</span>
            </div>
            <div className='step'>
              <div className='step-icon'>
                <Talk />
              </div>
              <span className='step-text'>Согласование принятых решений</span>
            </div>
            <div className='step'>
              <div className='step-icon'>
                <Plan />
              </div>
              <span className='step-text'>Разработка проекта вентиляции</span>
            </div>
            <div className='step'>
              <div className='step-icon'>
                <Apply />
              </div>
              <span className='step-text'>Согласование выполненных работ</span>
            </div>
          </div>
        </section>
      </div>
    </Layout>
  );
};

export default ProjectingContainer;
